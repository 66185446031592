/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'filter': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<mask id="svgicon_filter_a" maskUnits="userSpaceOnUse" x="0" y="4" width="18" height="11"><path pid="0" d="M.818 5.556a.84.84 0 01-.578-.228.759.759 0 01-.24-.55c0-.206.086-.404.24-.55A.84.84 0 01.818 4h16.364a.84.84 0 01.578.228c.154.146.24.344.24.55a.759.759 0 01-.24.55.84.84 0 01-.578.228H.818zm2.455 4.277a.84.84 0 01-.579-.227.759.759 0 01-.24-.55c0-.207.087-.405.24-.55a.84.84 0 01.579-.228h11.454a.84.84 0 01.579.228c.153.145.24.343.24.55a.759.759 0 01-.24.55.84.84 0 01-.579.227H3.273zm2.454 4.278a.84.84 0 01-.578-.228.759.759 0 01-.24-.55c0-.206.086-.404.24-.55a.84.84 0 01.578-.227h6.546a.84.84 0 01.578.227c.154.146.24.344.24.55a.759.759 0 01-.24.55.84.84 0 01-.578.228H5.727z" _fill="#fff"/></mask><g mask="url(#svgicon_filter_a)"><path pid="1" _fill="#fff" d="M0 0h18v18H0z"/></g>'
  }
})
